import { library, dom } from "@fortawesome/fontawesome-svg-core"

import { faGithub, faXTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import {
  faHome,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPlus,
  faEllipsisV,
  faSortDown,
  faSortUp,
  faSort,
  faSearch,
  faDownload,
  faUserTie,
  faFolderOpen,
  faCalendar,
  faUsers,
  faUser,
  faUserClock,
  faChartPie,
  faXmark,
  faKeyboard,
  faSignOutAlt,
  faCreditCard,
  faEdit,
  faTrash,
  faArchive,
  faCopy,
  faExclamationTriangle,
  faExpand,
  faCompress,
  faExclamationCircle,
  faClock,
  faTag,
  faClone,
  faCheck,
  faCircleQuestion,
  faCalendarAlt,
  faSave,
  faList,
  faPen,
  faChartLine,
  faPaperPlane,
  faUserCircle,
  faEyeSlash,
  faEye,
  faExternalLink,
  faKey,
  faEnvelope,
  faFilter,
  faTags,
  faBars,
  faArrowsRotate,
  faClipboard,
  faSpinner,
  faFileInvoiceDollar,
  faRocket,
  faCogs,
  faCalendarArrowDown,
  faFileCsv,
  faReceipt,
  faWandMagicSparkles,
  faBracketsCurly,
  faArrowRightFromBracket,
  faBell,
} from "@fortawesome/pro-regular-svg-icons"

// Brands icons
library.add(faGithub, faXTwitter, faLinkedin)

// Regular icons
library.add(
  faHome,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faPlus,
  faEllipsisV,
  faSortDown,
  faSortUp,
  faSort,
  faSearch,
  faDownload,
  faUserTie,
  faFolderOpen,
  faCalendar,
  faUsers,
  faUser,
  faUserClock,
  faChartPie,
  faXmark,
  faKeyboard,
  faSignOutAlt,
  faCreditCard,
  faEdit,
  faTrash,
  faArchive,
  faCopy,
  faExclamationTriangle,
  faExpand,
  faCompress,
  faExclamationCircle,
  faClock,
  faTag,
  faClone,
  faCheck,
  faCircleQuestion,
  faCalendarAlt,
  faSave,
  faList,
  faPen,
  faChartLine,
  faPaperPlane,
  faUserCircle,
  faEyeSlash,
  faEye,
  faExternalLink,
  faKey,
  faEnvelope,
  faFilter,
  faTags,
  faBars,
  faArrowsRotate,
  faClipboard,
  faSpinner,
  faFileInvoiceDollar,
  faRocket,
  faCogs,
  faCalendarArrowDown,
  faFileCsv,
  faReceipt,
  faWandMagicSparkles,
  faBracketsCurly,
  faArrowRightFromBracket,
  faBell,
)

document.addEventListener("turbo:load", () => {
  // Replace any existing <i> tags with <svg> and set up a MutationObserver to
  // continue doing this as the DOM changes.
  dom.watch()
})
